import { Link, Outlet, useMatches } from '@remix-run/react';
import type { RouteHandle } from '@remix-run/server-runtime';
import { useMemo } from 'react';
import { Logo } from '~/components/logo.tsx';
import { Icon } from '~/components/ui/icon.tsx';

export const handle = {
  mode: 'host',
} satisfies RouteHandle;

export const authStyles = {
  headingClassName: 'text-3xl xs:text-4xl font-semibold',
  subHeadingClassName: 'text-body-md mt-3 xs:text-body-lg text-muted-foreground',
};

export function isAuthHandle(handle: unknown): handle is RouteHandle {
  return (
    !!handle &&
    typeof handle === 'object' &&
    'auth' in handle &&
    typeof handle.auth === 'object' &&
    !!handle.auth
  );
}

const useIsSimplifiedLayout = () => {
  const matches = useMatches();
  const handle: RouteHandle | undefined = useMemo(() => {
    return matches.find((match) => isAuthHandle(match.handle))?.handle as RouteHandle;
  }, [matches]);

  return !!handle?.auth?.simplifiedLayout;
};

export default function AuthLayout() {
  const isSimplifiedLayout = useIsSimplifiedLayout();

  return (
    <div className="flex h-full w-screen flex-row">
      <Link to="/" className="absolute top-4 left-4 sm:top-8 sm:left-10">
        <Logo />
      </Link>
      {isSimplifiedLayout ? (
        <Outlet />
      ) : (
        <>
          <div className="h-full w-full p-4 sm:p-10 lg:w-1/2">
            <div className="pt-10" />
            <Outlet />
          </div>

          <div className="right-0 hidden h-full w-1/2 items-start justify-center pt-36 pb-8 lg:fixed lg:flex">
            <div className="relative flex items-center justify-center">
              <img
                src="/img/auth-bg.jpeg"
                className="mx-auto aspect-square size-[400px] rounded-3xl"
                alt="House Background"
              />
              <div className="-bottom-10 absolute">
                <img
                  src="/img/landing/host/skylar.jpeg"
                  alt="Student"
                  className="aspect-square max-h-44 max-w-44 rounded-full"
                />
                <img
                  src="/img/landing/universities/columbia-university.png"
                  alt="Columbia university"
                  className="-right-4 -top-2 absolute max-w-20"
                />
                <Icon
                  name="blue-checkmark"
                  className="-bottom-1 -left-1 absolute h-16 w-16 shadow-none"
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
